import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import SecurityContext from '@/modules/common/services/security-context.service'
import { AccessLevel } from '@/modules/common/enums/access-levels.enum'

@Component({
  name: 'GtrEditUserForm'
})
export default class GtrEditUserForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, default: null })
    user: any | undefined

    @Ref()
    readonly observerEditUserCompanyForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        editUserCompany: {
          name: null,
          email: null,
          two_factor_enabled: null,
          two_factor_method: null,
          two_factor_phone: null,
          access_level: null,
          password: null,
          password_confirmation: null
        },
        two_factor_options: [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ]
      }
    }

    accessLevelActions (value) {
      if (value === 'SUPER_ADMIN') {
        this.$data.editUserCompany.two_factor_enabled = 1
        this.$data.two_factor_options = [
          { value: 1, text: 'Yes' }
        ]
      } else {
        this.$data.editUserCompany.two_factor_enabled = null
        this.$data.two_factor_options = [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ]
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('user', { immediate: true })
    onUserValueChange (newUserValue: any) {
      if (newUserValue) {
        this.$data.editUserCompany = this._.cloneDeep(newUserValue)
      }
    }

    async mounted () {
      const securityContext = JSON.parse(Container.get(SecurityContext).context())
      const allAccessLevels = await this.getAllAccessLevels()

      if (securityContext) {
        const user = securityContext.user
        switch (user.access_level) {
          case AccessLevel.SUPER_ADMIN: {
            this.$data.accessLevels = allAccessLevels
            break
          }
          case AccessLevel.RESELLER_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.RESELLER_ADMIN, text: 'Reseller' },
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_USER: {
            this.$data.accessLevels = [
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          default: {
            break
          }
        }
      }
    }

    private async getAllAccessLevels () {
      const response = await this.$store.dispatch('company/fetchUserGroups')
      const allAccessLevels: any[] = []
      for (const userGroupKey in response.data) {
        const userGroup = response.data[userGroupKey]
        allAccessLevels.push({ value: userGroup.name, text: userGroup.description })
      }
      return allAccessLevels
    }

    async submit () {
      try {
        this.$data.submitting = true
        const company_uuid = this.$store.state.company.company.uuid
        const user_uuid = this.$store.state.user.user.uuid
        this.$data.editUserCompany.company_uuid = company_uuid
        this.$data.editUserCompany.user_uuid = user_uuid
        if (!this.$data.editUserCompany.password || this.$data.editUserCompany.password === null) {
          delete this.$data.editUserCompany.password
        }
        await this.$store.dispatch('company/updateUser', this.$data.editUserCompany)
        Container.get(Notification).success('User successfully updated.')
        this.$bus.$emit('gtr.level-two.edit-user')
        this.$emit('close')
        this.clearFormAndModel()
      } catch (error) {
        const errors = error.data.errors
        if (errors) {
          this.showServerErrors(errors)
        }
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }

    handleCloseForm () {
      this.$data.editUserCompany = this._.cloneDeep(this.$store.state.user.user)
      this.$emit('close')
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerEditUserCompanyForm.setErrors(errors)
    }

    private clearFormAndModel () {
      this.$data.editUserCompany = {
        name: null,
        email: null,
        two_factor_enabled: null,
        two_factor_method: null,
        two_factor_phone: null,
        access_level: null,
        password: null,
        password_confirmation: null
      }
      this.observerEditUserCompanyForm.reset()
    }
}
