import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrGlobalSolutionLayout from '@/modules/common/views/layouts/level-two/global-solution/global-solution.layout.vue'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrEditUserForm from './forms/edit/edit-user.form.vue'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Vue from 'vue'
@Component({
  name: 'GtrUserCompanyDetailsView',
  computed: {
    ...mapState('user', ['user']),
    ...mapState('security', ['currentUser']),
    allowToEditUser () {
      if (this.$store.state.security.currentUser && this.$store.state.user.user) {
        return Vue.prototype.$haveLowerAccessLevel(this.$store.state.security.currentUser.access_level, this.$store.state.user.user.access_level)
      }
      return false
    }
  },
  components: {
    'gtr-edit-company-user-form': GtrEditUserForm
  }
})
export default class GtrUserCompanyDetailsView extends GtrSuper {
  data () {
    return {
      loading: false,
      forms: {
        showEditCompanyUserForm: false,
        deletingUser: false
      },
      userToEdit: null
    }
  }

  async created () {
    this.$emit('update:layout', GtrGlobalSolutionLayout)
    this.$bus.$on('gtr.level-two.edit-user', this.handleAction)
    if (this.$route.params.user_uuid) {
      await this.fetchUser(this.$route.params.user_uuid)
    }
  }

  handleShowEditCompanyUserForm () {
    this.$data.forms.showEditCompanyUserForm = true
    this.$data.userToEdit = this.$store.state.user.user
  }

  handleCloseEditCompanyUserForm () {
    this.$data.forms.showEditCompanyUserForm = false
    this.$data.userToEdit = null
  }

  async handleAction () {
    if (this.$route.params.user_uuid) {
      await this.fetchUser(this.$route.params.user_uuid)
    }
  }

  async handleDeleteUser () {
    this.$data.forms.deletingUser = true
  }

  async onDeleteUserAction (payload: any) {
    if (payload.confirm) {
      this.$data.loading = true
      try {
        this.$store.dispatch('common/showLoader', { value: true })
        const uuid = this.$route.params.user_uuid
        await this.$store.dispatch('company/removeUser', uuid)
        this.$router.push({ name: 'level-two.company.info', params: { uuid: this.$route.params.uuid } })
        Container.get(Notification).success('User successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$store.dispatch('common/hideLoader')
      }
      this.$data.loading = false
      this.$data.forms.deletingUser = false
    } else {
      this.$data.forms.deletingUser = false
    }
  }

  private async fetchUser (user_uuid: string) {
    try {
      this.$data.loading = true
      const company = await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      const user = company.admin_users.find((user: any) => user.uuid === user_uuid)
      this.$store.commit('user/SET_USER', user)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
